// @import "~primeng/resources/themes/md-light-indigo/theme.css";
// @import "~primeicons/primeicons.css";

:root {
    --grey-400: rgb(244, 244, 248)
}

// Text
.text-small,
.text-small .p-button-label {
    font-size: .7rem !important;
}

.text-primary {
    color: var(--primary-color) !important
}

.w-full {
    width: 100% !important;
}

.text-primary {
    color: var(--primary-color) !important
}

// Utilities
.filled {
    background-color: var(--grey-400) !important;
    border-color: transparent !important;
}

.p-tooltip-text{
    font-size: 10px !important;
}



// Button Style
.p-button {
    flex: 1 !important;
    justify-content: center !important;
    align-items: center !important;
}

.p-button-label {
    flex: none !important;
}

.p-button .p-button-icon.p-button-icon-left {
    margin-right: 8px;
}

.p-button:not(.p-button-lg, .p-button-xs) {
    font-size: 0.875rem !important;
    padding: 0.5rem 0.7rem !important;
    font-weight: normal !important;
}

.p-button:not(.p-button-outlined, .p-button-text, .p-button-plain, .p-button-light, .p-button-secondary, .p-disabled, button:disabled) {
    background-color: var(--primary-color) !important;
}

.p-button:not(.p-button-outlined, .p-button-text, .p-button-plain):enabled:hover {
    background: rgba(var(--primary-color-rgb), 0.80) !important;
}


// .p-button.p-button-light:not(.p-button-outlined, .p-button-text, .p-button-plain) {
//     background-color: var(--primary-color) !important;
// }

.p-button:enabled:active {
    background: var(--primary-50) !important;
}

.p-button.p-button-outlined {
    color: var(--primary-color) !important;
}

.p-button.p-button-outlined:enabled:hover {
    color: var(--primary-color) !important;
}

.p-button.p-button-outlined:enabled:active {
    color: var(--primary-color) !important;
}

.p-button.p-button-text {
    color: var(--primary-color) !important;
}

.p-button.p-button-text:enabled:hover {
    color: var(--primary-color) !important;
}

.p-button.p-button-text:enabled:active {
    color: var(--primary-color) !important;
}

.p-button.p-button-secondary {
    background-color: rgba(var(--primary-color-rgb), 0.16) !important;
    color: rgba(var(--primary-color-rgb), 1) !important;
}

.p-button.p-button-secondary:enabled:hover,
.p-button.p-button-secondary:enabled:active {
    background-color: rgba(var(--primary-color-rgb), 0.20) !important;
}

// Input Text
.p-inputtext {
    font-size: 0.875rem !important;
    padding: 0.6rem !important;
    width: 100% !important;
}

.p-input-icon-left {
    width: 100%;
}

.p-input-icon-left>.p-inputtext {
    padding-left: 2.5rem !important;
}

.p-inputtext.input-fill {
    background-color: var(--grey-400);
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
    color: var(--primary-color) !important;
}

.p-inputtext:enabled:focus {
    border-color: var(--primary-color) !important;
}

// Dropdown
.p-dropdown:not(.p-disabled).p-focus {
    border-color: var(--primary-color) !important;
}

.p-dropdown .p-dropdown-trigger-icon,
.p-dropdown-clear-icon {
    font-size: 10px;
}

.p-dropdown .p-dropdown-clear-icon {
    right: 0rem !important;
    right: 1.8rem !important;
}

.p-dropdown-clear-icon {
    margin-top: -.3rem !important;
}

.p-dropdown-xs {
    height: 2rem !important;
}

.p-dropdown-xs .p-dropdown-label {
    margin-top: -5px !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: rgba(var(--primary-color-rgb), 1) !important
}


// Mat Paginator
::ng-deep .mat-paginator-outer-container .mat-paginator-container {
    justify-content: none !important;
    padding: 0 !important;
    flex-wrap: wrap-reverse;
}