@import "~@fortawesome/fontawesome-free/css/all.css";
@import '~firebaseui/dist/firebaseui.css';
@import "~flag-icons/css/flag-icons.min.css";

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Regular.ttf') format("truetype");
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-Regular.ttf') format("truetype");
}

:root {
  --primary-color: #215494;
  --primary-color-rgb: 33, 84, 148;
  --secondary-color: #215494;
  --secondary-color-rgb: 33, 84, 148;
  --primary-light-color: #edf3f9;
  --primary-light-color-rgb: 237, 243, 249;
  --text-color: white;
}

router-outlet {
  position: relative; /* Required for z-index to work */
  z-index: 10; /* Adjust as needed */
}

html {
  font-family: 'Roboto';
}

body {
  margin: 0;
  padding: 0;
  //min-height: 100vh;
}

button,
button:focus {
  outline: none !important;
}

.row {
  display: flex;
  margin: 0 -10px;
  margin-bottom: 10px;
}

.col {
  flex: 1;
  margin: 0 5px;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-6 {
  flex: 6;
}

.flex-7 {
  flex: 7;
}

.flex-8 {
  flex: 8;
}

.flex-9 {
  flex: 9;
}

.flex-10 {
  flex: 9;
}

.flex-11 {
  flex: 9;
}

.flex-12 {
  flex: 9;
}

.flex-13 {
  flex: 9;
}

.flex-14 {
  flex: 9;
}

.flex_gap_15 {
  gap: 15px;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

/* Material Mods */

.mat-form-field-infix {
  width: 100% !important;
}


.mat-button.md-small,
.mat-raised-button.md-small,
.mat-stroked-button.md-small,
.mat-flat-button.md-small {
  min-width: 1%;
  padding: 0 8px;
}

button.mat-warn {
  color: #a23333 !important;
  // background: #f0dede !important
}

.mat-stroked-button:not([disabled]) {
  border-color: inherit !important;
}

.inner_icon_txt_btn {
  padding-left: 30px;
  position: relative;
  font-size: 12px;

  .mat-icon {
    position: absolute;
    left: 0;
    top: 5px;
  }
}


.mat-form-field.full-width {
  width: 100% !important;
}

.mat-checkbox-layout .mat-checkbox-label {
  white-space: normal !important;
}

.planogram-editor-product-orgenize-checkbox-container .mat-checkbox-layout {
  display: flex;
}

.planogram-editor-product-orgenize-checkbox-container .mat-checkbox-layout .mat-checkbox-label {
  color: #6b6b6b;
  font-size: 12px;
  line-height: 16px;
}

.planogram-editor-product-orgenize-checkbox-container .mat-checkbox-inner-container {
  margin-top: 2px;
}

mat-form-field.aligned {
  bottom: -5px;
}

.mat-form-field {
  width: 100%;
}

.no-padding-dialog-container .mat-dialog-container {
  padding: 0;
}

.height-with-scroll .mat-dialog-container {
  max-height: 600px;
  overflow-y: auto
}

th.mat-header-cell.centered .mat-sort-header-container {
  justify-content: center;
  padding-left: 12px;
}


/* custom table */
.pre-table-header {
  display: flex;
  padding: 0 24px;
  align-items: center;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
}

.pre-table-header .mat-form-field-prefix .mat-icon {
  bottom: -3px;
  font-size: 16px;
  margin-right: 4px;
  position: relative;
}

.pre-table-header-btn-container:not(:last-child) {
  margin: 0 16px;
}

table.custom-table {
  width: 100%;
}

table.custom-table tr.mat-header-row {
  height: 44px;
}

// table.custom-table th.mat-header-cell {
//   padding-top: 24px;
// }

table.custom-table+.mat-paginator .mat-paginator-container {
  padding: 0 20px;
  justify-content: space-between;
}

.mat-menu-content {
  padding: 8px;
}

.mat-menu-content .mat-pseudo-checkbox-checked::after {
  top: 4.4px;
  width: 10px;
}

.table-main {
  min-height: 100vh;
  display: flex;
  padding: 24px 2.5rem 2rem;
}

.table-main.block {
  display: block;
}

.table-content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.table-header {
  display: flex;
  margin-bottom: 20px;
  align-items: flex-end;
  justify-content: space-between;
}

.table-title-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.table-title {
  color: #4a6572;
  font-size: 24px;
  margin-bottom: 20px;
}

.table-sub-title {
  color: #4a6572;
  font-size: 14px;
}

.title {
  grid-area: title;
  color: white;
  font-size: 32px;
  line-height: 39.01px;
}

.sub-title {
  grid-area: sub-title;
  color: white;
  font-size: 16px;
  line-height: 19.5px;
}

/* Material Icons */

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v140/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

/* misc */

.loading-indicator {
  position: fixed;
  z-index: 1001;
  /* Override dialog */
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading-spinner {
  z-index: 9999;
  position: absolute;
  z-index: 1002;
  top: 50%;
  left: 48%;
}

.images-menu.mat-menu-panel {
  min-width: 72px;
}

/* .categories-menu.mat-menu-panel {
  max-height: calc(100vh - 80px) !important;
} */

.rtl_lang .mat-paginator-range-label {
  direction: rtl;
}

h2 {
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: 20px;
}

h4 {
  font-size: 1.2em;
  font-weight: 400;
  margin-bottom: 20px;
}

// override firebase ui styles
.firebaseui-card-footer {
  opacity: 0;
  height: 20px;
}

.overflow-y-auto {
  overflow-y: auto;
}

.height-100 {
  height: 100%;
}

.min-height-100 {
  min-height: 100%;
}

.width-100 {
  width: 100%;
}

.light_card {
  border: rgb(216, 216, 216) solid 1px;
  border-radius: 6px;
  padding: 15px
}

.formSlideToggle {
  color: #757575;
  margin-bottom: 20px;

  >.mat-slide-toggle {
    margin-left: 10px;
  }
}

.mat-elevation-z8.bordered {
  padding: 10px;
  background: #fff;
  border-radius: 10px;
}

.ire_btn_01 {
  background-color: #dde6f0 !important;
  color: #3364a3 !important;
  // float: right;

  .mat-icon {
    margin-right: 5px;
  }
}

.full_iframe {
  border: none;
  height: calc(100vh - 105px);
  width: 100%;
}

.nowrap {
  white-space: nowrap;
}

.selectSearch {
  width: 100%;
  padding: 10px 15px;
  input {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #8c8c8c;
  }
}

.text-center {
  text-align: center;
}

.link-button {
  color: #007bff;
  text-decoration: underline;
  margin: 0;
  padding: 0;
  background: none!important;
  border: none;
  padding: 0!important;
}